<template>
    <div class="navbar-container d-flex content align-items-center">

        <!-- Nav Menu Toggler -->
        <ul class="nav navbar-nav d-xl-none">
            <li class="nav-item">
                <b-link
                    class="nav-link"
                    @click="toggleVerticalMenuActive"
                >
                    <feather-icon
                        icon="MenuIcon"
                        size="21"
                    />
                </b-link>
            </li>
        </ul>

        <!-- Left Col -->
        <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
            <dark-Toggler class="d-none d-lg-block" />
        </div>

        <b-navbar-nav class="nav align-items-center ml-auto">
            <b-nav-item-dropdown
                right
                toggle-class="d-flex align-items-center dropdown-user-link"
                class="dropdown-user"
            >
                <template #button-content>
                    <div class="d-sm-flex d-none user-nav">
                        <p v-if="userData" class="user-name font-weight-bolder mb-0">
                            {{userData.firstname}} {{userData.lastname}}
                        </p>
                        <span class="user-status">Admin</span>
                    </div>
                    <b-avatar
                        size="40"
                        variant="light-primary"
                        badge
                        src=""
                        class="badge-minimal"
                        badge-variant="success"
                    />
                </template>
                <b-dropdown-item @click="logout" link-class="d-flex align-items-center">
                    <feather-icon
                        size="16"
                        icon="LogOutIcon"
                        class="mr-50"
                    />
                    <span>Odjava</span>
                </b-dropdown-item>
            </b-nav-item-dropdown>
        </b-navbar-nav>
    </div>
</template>

<script>
    import {
        BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BAvatar
    } from 'bootstrap-vue'
    import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

    export default {
        components: {
            BLink,
            BNavbarNav,
            BNavItemDropdown,
            BDropdownItem,
            BAvatar,

            // Navbar Components
            DarkToggler
        },
        props: {
            toggleVerticalMenuActive: {
                type: Function,
                default: () => {}
            }
        },
        methods: {
            async logout() {
                try {
                    await this.$store.dispatch('user/setLogout')
                    await this.$http.get('/api/private/v1/logout')
                    await this.$router.push({name: 'login'})
                } catch (error) {
                    this.$printError('Prišlo je do napake pri odjavi')
                }
            }
        },
        computed: {
            userData() {
                const userData = this.$store.getters['user/getUserData']
                if (userData) return userData
                return null
            }
        }
    }
</script>
