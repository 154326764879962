export default [
  {
    title: 'Domov',
    route: 'home',
    icon: 'HomeIcon'
    // required_permission: ['admin']
    // če dodaš array required_permission, se bodo na levi strani v navbaru izrisovali samo routi,
    // z dovoljenimi permissioni
    // koda ki to dejansko preverja se nahaja v:
    // src\@core\layouts\layout-vertical\components\vertical-nav-menu\components\vertical-nav-menu-items\VerticalNavMenuItems.vue
  },
  {
    title: 'Vloge',
    route: 'submissions',
    icon: 'ClipboardIcon'
  },
  {
    title: 'Duplikati',
    route: 'duplicates',
    icon: 'CopyIcon'
  },
  {
    title: 'Termini',
    route: 'terms',
    icon: 'ListIcon'
  },
  {
    title: 'Občine',
    route: 'municipalities',
    icon: 'ArchiveIcon'
  },
  {
    title: 'Uporabniki',
    route: 'users',
    icon: 'UsersIcon'
  },
  {
    title: 'Načini letovanja',
    route: 'submission_types',
    icon: 'TypeIcon'
  },
  {
    title: 'Zdravniki',
    route: 'doctors',
    icon: 'ActivityIcon'
  },
  {
    title: 'Ustanove',
    route: 'institutions',
    icon: 'HomeIcon'
  },
  {
    title: 'Osnovne šole',
    route: 'primary_school',
    icon: 'BookOpenIcon'
  },
  {
    title: 'Premaknjene vloge',
    route: 'moved_submissions',
    icon: 'BookIcon'
  }
]
